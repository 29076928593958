<template>
  <div class="home" id="quotaReviewList">
    <div style="text-align: left" >
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="计薪汇总表编号：">
          <el-input v-model="queryForm.payrollSummary" placeholder="请输入查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="queryForm.status" placeholder="请选择">
            <el-option label="全部状态" value="-1"></el-option>
            <el-option v-for="(itme,i) in itemList" :key="i" :label="itme.name" :value="itme.datavalue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>
          <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
     <div style="text-align: left;margin-bottom: 20px;">
      <el-button type="primary" icon="el-icon-plus" @click="dialogFormVisible = true">上传汇总表</el-button>
      <!-- downloadSalary -->
      <!-- <el-button type="primary" icon="el-icon-plus" @click="dialogFormVisible = true">下载发薪模板</el-button> -->
    </div>
    <div>
        <el-table  :header-cell-style="tableHeader" :cell-style="tableCell" :data="tableData" fit border style="width: 100%">
        <el-table-column type="index" width="50" label="" />
        <el-table-column prop="payrollSummary" :show-overflow-tooltip="true"  label="计薪汇总表编号" />
        <el-table-column prop="status" :show-overflow-tooltip="true"  label="状态">
          <template slot-scope="scope">
            <div v-for="(item, index) in itemList" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.status">
                {{  item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="examinepeople" :show-overflow-tooltip="true" label="审核人" />
        <el-table-column prop="examineTime" :show-overflow-tooltip="true" label="审核时间" />
        <el-table-column prop="updateTime" :show-overflow-tooltip="true" label="最后更新时间" />
        <el-table-column prop="submitUser" :show-overflow-tooltip="true" label="提交人员" />
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <el-button @click="noDetails(scope.row)" type="text" size="small">详情</el-button> 
            <el-button type="text" size="small" @click="submitAudit(scope.row,0)" v-if="scope.row.status == 0" style="color: #09BF88">提交审核</el-button> 
            <el-button type="text" size="small" @click="repealAudit(scope.row,1)" v-else-if="scope.row.status == 1" style="color: #FFAC1D">撤销审核</el-button>
            <el-button type="text" size="small" @click="submitAudits(scope.row,0)" v-if="scope.row.status == 4 || scope.row.status == 3"  style="color: #09BF88">重新提交</el-button> 
            <el-button type="text" size="small" @click="repealAudit(scope.row,5)" v-if="scope.row.status != 5 && scope.row.status != 4"  style="color: #F78969">作废</el-button> 
          </template>
        </el-table-column>
      </el-table>
     <el-pagination
        id="page"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        @prev-click="currentChange"
        @next-click="currentChange"
        :page-sizes="[5, 10, 20]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
        :visible.sync="auditMsg.dialogVisible"
        width="30%"
        >
        <span :class="auditMsg.msgClass">{{auditMsg.msg}}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="auditMsg.dialogVisible = false">取 消</el-button>
          <el-button :class="auditMsg.buttonClass" type="primary" @click="changeState">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="上传汇总表" :visible.sync="dialogFormVisible" width="50%" style="text-align: initial;" @closed="afteraddOrUpdateDialogClose">
        <!-- 内层 -->
        <el-form :model="updateForm" :label-position="'left'" ref="updateForm" :rules="updateFormRules" label-width="150" class="updateForm" >
          <el-form-item label="方案：" prop="planId" >
            <el-select v-model="updateForm.planId" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传汇总表"  prop="isFile" >
            <el-upload
              class="upload-demo"
              ref="upload"
              :action="upload"
              :on-preview="handlePreview"
              :on-error="handleError"
              :on-remove="handleRemove"
              :on-success="handlesuccess"
              :file-list="fileList"
              :limit=1
              accept=".xls,.xlsx"
              :data="updateForm"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件。</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitUploadFrom">确 定</el-button>
        </div>
      </el-dialog>

  </div>
</template>
<script>

export default {
    data() {
      return {
        dialogFormVisible: false,
        updateForm:{
          planId: '',
          isFile: ''
        },
        updateFormRules: {
          planId:[
             { required: true, message: '请选择部门！', trigger: 'change' }
          ],
          isFile:[
             { required: true, message: '请选择文件！', trigger: 'change' }
          ]
        },
        upload:process.env.VUE_APP_BASE_URL+'/salaryCalculation/importSalaryCalculation',
        options: [],
        queryForm: {
          payrollSummary: '',
          status: '',
          pageNo: 1,
          pageSize: 10
        },
        total:0,
        // 这个站点对象集合
        fullscreenLoading: false,
        // 状态字典列表
        summaryStateList:[],
        // 表格对象
        tableData: [],
        innerVisible: false,
        fileList: [],
        auditMsg: {
          dialogVisible: false,
          msg: '确认撤销审核该汇总表吗？',
          msgClass: 'submitMsg',
          buttonClass: 'submitButton',
          // 提交为0，撤销为1
          status: 0
        },
      changeStateParams:{
        id: '',
        status: null,
        sessionId: ''
      },
      itemList: [],
      diction: {
					code: "summaryStatus",
				},
      }
    },
    computed: {
    },
    watch: {
    },
    // 实例创建完成后调用

    mounted: function(){
				this.findDictionariesItemList(this.diction);
		},
    async created(){
	    this.loadTable();
      this.loadPaidPlans();
    },
    methods: {
      // 加载方案列表
      async loadPaidPlans(){
        await this.api.paidPlanApi.listPaidPlans({}).then(res => {
          res = res.data;
          console.log(res);
          if(res.code == 200){
           this.options = res.data;
          }else{
            this.$message.error(res.msg);
          }
        })
      },
      //  清空弹窗表单
      afteraddOrUpdateDialogClose(){
          this.resetForm('updateForm');
          this.$refs.upload.$data.uploadFiles = [];
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      // 点击文件列表中已上传的文件时的钩子
      handlePreview(file) {
        console.log('handlePreview!');
      },
      // 上传失败的钩子
      handleError(err, file, fileList){
        this.$message.error('上传失败！');
      },
      // 删除一选择的上传文件函数
      handleRemove(file, fileList) {
        console.log('handleRemove!');
        this.updateForm.isFile = null;
      },
      // 上传成功的钩子
      handlesuccess(response, file, fileList){
        this.updateForm.groupId = [];
        this.fileList = []
        this.dialogFormVisible = false;
        this.fullscreenLoading = true;
        setTimeout(() => {
          this.fullscreenLoading = false;
          this.$message({
          message: '上传成功！',
          type: 'success'
        });
          this.loadTable();
        }, 2000);
        console.log('handlesuccess');
      },
      submitUploadFrom(){
        console.log('开始上传');
        this.updateForm.isFile = this.$refs.upload.$data.uploadFiles.length > 0 ? true : null;
        this.$refs['updateForm'].validate((valid) => {
          if (valid) {
            console.log(111);
            this.updateForm.sessionId = this.$getCookie("sessionId");
            this.$refs.upload.submit();
            console.log('上传成功！');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        
      },
      // 分页查看薪资信息列表
		async loadTable(){
			this.loading = true;
			let flag = this;
			//getStaffList
      console.log(this.queryForm);
			await this.api.payrollSummary.listPayrollSummary(this.queryForm).then((res) => {
				res = res.data
				console.log("----计薪列表-----",res);
				// flag.loading = false;
				if(res.code == 200){
					flag.tableData = res.data.records;
					flag.total = res.data.total
				}else{
            this.tableData =  null;
            this.total = 0;
            this.$message.error(res.msg);
          }
			})
		},
    toFloat(val){
			if(this.isEntity(val)){
				return '';
			}
			return parseFloat(val)/10000+"%";
		},
      // 查看函数
      noDetails(row){
        this.$router.push({path: '/salaryCalculation',query: {id: row.id,payrollSummary:row.payrollSummary,updateTime:row.updateTime}})
      },
      // 作废
      cancellation(){
        
      },
      // 点击提交审核 changeState
      async submitAudit(row,status){
        this.auditMsg.dialogVisible = true;
        this.auditMsg.msg='确认提交审核该汇总表吗？'
        this.auditMsg.msgClass='submitMsg'
        this.auditMsg.buttonClass='submitButton'
        this.auditMsg.status=0
        this.changeStateParams.id=row.id;
        this.changeStateParams.status=status;
        // this.changeState(params);
      },

      async submitAudits(row,status){
        this.auditMsg.dialogVisible = true;
        this.auditMsg.msg='确认要重新提交审核该汇总表吗？'
        this.auditMsg.msgClass='submitMsg'
        this.auditMsg.buttonClass='submitButton'
        this.auditMsg.status=0
        this.changeStateParams.id=row.id;
        this.changeStateParams.status=status;
        // this.changeState(params);
      },
      // 点击撤销审核
      async repealAudit(row,status){
        if(status==1){
          this.auditMsg.dialogVisible = true;
          this.auditMsg.msg='确认撤销审核该汇总表吗？'
          this.auditMsg.msgClass='repealMsg'
          this.auditMsg.buttonClass='repealButton'
          this.auditMsg.status=1
          this.changeStateParams.id=row.id;
          this.changeStateParams.status=status;
        }else if(status==5){
          this.auditMsg.dialogVisible = true;
          this.auditMsg.msg='确认要作废表单吗？'
          this.auditMsg.msgClass='cancellationMsg'
          this.auditMsg.buttonClass='cancellationButton'
          this.auditMsg.status=1
          this.changeStateParams.id=row.id;
          this.changeStateParams.status=status;
        }
        
        // this.changeState(params);
      },
      // parseXlsxPageChange
      parseXlsxPageChange(page){
        console.log(page+'page');
        this.parsePage.pageNo = page;
      },
      //parseXlsxPagesizeChange 
      parseXlsxPagesizeChange(size){
        console.log(size+'size');
        this.parsePage.pageSize = size;
      },
      // 分页器 size改变是触发
       //current-change
      currentChange(page){
        this.queryForm.pageNo = page;
        this.loadTable();
      },

      // size-change
      sizeChange(size){
        this.queryForm.pageSize = size;
        this.loadTable();
      },
      // 改变状态
     async changeState(){
        this.changeStateParams.sessionId=this.$getCookie("sessionId");
        let flag = this;
        
        if(this.changeStateParams.status == 0){
          // 提交审核
          console.log("--------one",this.changeStateParams.status)
          await this.api.payrollSummary.updateSubmitReviewStatus(this.changeStateParams).then(res =>{
            console.log("------res1",res)
          if(res.status==200){
            flag.loadTable();
            flag.$message({
              message: '操作成功！',
              type: 'success'
            });
          }else{
           flag.$message.error('操作失败！');
          }
          flag.auditMsg.dialogVisible=false;
        })
        //撤销审核
        console.log("--------2",this.changeStateParams)
        } else if(this.changeStateParams.status == 1){
            await this.api.payrollSummary.updateRevokeApprovalStatus(this.changeStateParams).then(res =>{
              console.log("------res2",res)
              if(res.data.code==200){
                flag.loadTable();
                flag.$message({
                  message: '操作成功！',
                  type: 'success'
                });
              }else{
                flag.$message.error('操作失败！');
              }
                flag.auditMsg.dialogVisible=false;
              })
        }
          else{
          // 这里是作废的
          console.log("--------3",this.changeStateParams)
          await this.api.payrollSummary.updateNullifyStatus(this.changeStateParams).then(res =>{
            console.log("------res3",res.data)
            if(res.data.code==200){
              flag.loadTable();
              flag.$message({
                message: '操作成功！',
                type: 'success'
              });
            }else{
              flag.$message.error('操作失败！');
            }
              flag.auditMsg.dialogVisible=false;
            })
        }
        
      },
      // 重置
      onReset(){
        this.queryForm.payrollSummary='';
        this.queryForm.status='';
      },
      async findDictionariesItemList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.itemList = response.data.data;
			},
      tableHeader(column) {
        return "height:36px;background-color:#fafafa;color:#333333;padding:0";
      },
      tableCell(column) {
        return "height:40px;background-color:#fafafa;color:#000000;padding:0";
      },
    },
}
</script>

<style scoped>
  .submitMsg{
    color: #09BF88;
    font-size: 1.5rem;
    
  }
  .submitButton{
    background-color: #09BF88;
    border: none;
  }
  .submitButton:hover{
    background-color: #3ACCA0;
  }
  .repealMsg{
    color: #FFAC1D;
    font-size: 1.5rem;
  }
  .repealButton{
    background-color: #FFAC1D;
    border: none;
  }
  .repealButton:hover{
    background-color: #FFBD4A;
  }

  .cancellationMsg{
    color: #F78969;
    font-size: 1.5rem;
  }
  .cancellationButton{
    background-color: #F78969;
    border: none;
  }
  .cancellationButton:hover{
    background-color: #F789;
  }
/* -------------------------------------- */

#page {
  float: right;
  margin-top: 15px;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

.el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

.el-drawer.rtl {
  overflow: scroll;
}

.updateForm{
  width: 90%;
  margin: 0 auto;
}
.parseXlsxBottom{
  text-align: end;
  padding: 20px;
}
</style>


